/*
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2024-04-11 09:15:01
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2024-04-11 14:30:18
 * @FilePath: \47-order-system\src\api\base.js
 * @Description: ,`customMade`, koroFileHeader : https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const base = {
       getTop1From: '/api/pk/getTop1FromPKCustomerManagementDeliveryByTime',
       getPkCustomer: '/api/pk/getPkCustomerManagementDeliveryFromBetweenTime',
       getSPOrderSelect: '/api/pk/getSPOrderSelect',
       obtainAllDishes: '/api/pk/obtainAllDishes',
       getSumItemQuantity: '/api/pk/getSumItemQuantity',
       savePKCustomerManagementDeliveryForecastXItem: '/api/pk/savePKCustomerManagementDeliveryForecastXItem',
       updateOrInsertOrDrop: '/api/pk/updateOrInsertOrDrop',
       updatePKCustomerManagementDeliveryForecastXItem: '/api/pk/updatePKCustomerManagementDeliveryForecastXItem',
       updatePKCustomerManagementDelivery: '/api/pk/updatePKCustomerManagementDelivery',
       getCompositionOfAllMeals: '/api/pk/getCompositionOfAllMeals',
       modifyRatin: '/api/pk/modifyRating',
       quickAdd: '/api/pk/quickAdd',
       // Login
       queryUser: '/api/pk/queryUser',
       toLogin: '/api/pk/toLogin',
       toLoginEs:"/api/newPk/lognOn",
       saveEditEmailXX:"/api/newPk/updatePassword",
       updateCustomerWhereinPassword: '/api/pk/updateCustomerWhereinPassword',
       getUserInfo: '/api/pk/getUserInfo',
       updateUserlnfo: '/api/pk/updateUserInfo',
       sendCodeInfo: '/api/pk/sendCodeInfo',
       sendCodeInfoForRegister: '/api/pk/sendCodeInfoForRegister',
       contrastCode: '/api/pk/contrastCode',
       updaeCallUpdate:'/api/pk/callUpdate',
       selectDietTypes:'/api/pk/selectDietTypes/',
       callSpPrefsView:'/api/pk/callSpPrefsView/',
       SelectSPCustIngredView:'/api/newPk/call/',
       updateSPCustIngredEdit:'/api/newPk/update/',
       selectOrdersView:'/api/newPk/selectOrdersView/',
       selectOrderSelect:'/api/newPk/selectOrderSelect/',
       selectShowEvaluation:'/api/newPk/selectShowEvaluation/',
       updatePkOrderItem:'/api/newPk/update/PkOrderItem',
       getOrderBetweenTime:'/api/newPk/getOrderBetweenTime/',
       updatePkOrderTime:'/api/newPk/updatePkOrderTime',
       getSumOriQty:'/api/newPk/getSumOriQty/',
       getSPMealSelect:'/api/newPk/getSPMealSelect/',
       selectSum:'/api/newPk/selectSum/',
       deleteItem:'/api/newPk/deleteItem/',
       addPkItem:'/api/newPk/addPkItem/',
       addPkItemNew:'/api/routineDishes/routineNewAndAddedDishes',
       updatePkOrderItemMinus:'/api/newPk/updatePkOrderItemMinus/',
       routineNewAndReduceDishes:'/api/routineDishes/routineNewAndReduceDishes/',
       updatePkOrderItemAdd:'/api/newPk/updatePkOrderItemAdd/',
       selectCountMealSelect:'/api/newPk/selectCountMealSelect/',
       getPkCustomerFromCusKey:'/api/newPk/getPkCustomerFromCusKey',
       getBenPrograms:'/api/pk/SP_BenPrograms',
       selectOrdersViews:'/api/newPk/selectOrdersViews/',
       sPAuthStatus:'/api/newPk/sPAuthStatus',
       selelctPkCancellationReason:'/api/newPk/selelctPkCancellationReason',
       SendUserNameToEmail:'/api/pk/SendUserNameToEmail',
       getSPMealSelectOne:'/api/newPk/getSPMealSelectOne',
       getAuthView:'/api/newPk/getAuthView',
       upStatus:"/api/newPk/upStatus",
       getCustViewForCid:"/api/newPk/getCustViewForCid",
       ExecCusStatus:"/api/newPk/ExecCusStatus",
       getCallCustView:"/api/newPk/getCallCustView",
       getSurveyPending:"/api/newPk/getSurveyPending",
       validateToken:"/api/newPk/selectToken",
       getModifierList:"/api/pk/getModifierList",
       getSpPrefsView:"/api/pk/getSpPrefsView",
       changeOrder:"/api/newPk/changeOrder",
       getSSOToken: '/mercato-api/external/benefits-program/obtain-sso-token',

}
export default base
